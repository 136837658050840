.side-drawer {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  width: 300px;
  z-index: 200;
  padding: 20px;
}

.side-drawer li {
  margin-bottom: 35px;
  font-size: 18px;
}

.side-drawer ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
}

.drop-down {
  color: black;
}

.side-drawer a {
  color: gold;
  text-decoration: none;
}

.dropdown-item a {
  color: black;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: goldenrod;
}

.header {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gold;
}
