.cart {
  height: 100%;
  background-color: black;
  padding: 10px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: -300px;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: 300ms ease-out;
}

.cart.open {
  transform: translateX(0);
}

.cart-buttons {
  display: flex;
  flex-direction: column;
}

.cart-lineitem {
  width: 100%;
  background-color: rgb(31, 31, 31);
  padding: 5px;
  border-radius: 5px;
  margin: 3px 0;
}

.cart-lineitem img {
  width: 100%;
}

.cart-lineitem-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.cart-remove-link {
  text-align: right;
  color: rgb(100, 100, 100);
}

.cart-remove-link:hover {
  cursor: pointer;
  color: whitesmoke;
}

.cart li {
  margin-bottom: 4%;
}

.cart ul {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  list-style: none;
}

.drop-down {
  color: black;
}

.cart a {
  color: darkgrey;
  text-decoration: none;
}

.dropdown-item a {
  color: black;
}
.cart a:hover,
.cart a:active {
  color: goldenrod;
}

.empty-cart {
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gold;
}

.quantity-button {
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  /* padding-bottom: 10px; */
  background-color: transparent;
  color: grey;
  margin: 5px;
}

.quantity-button:hover {
  cursor: pointer;
  /* background-color: rgba(100, 100, 100, .5); */
  color: black;
}

.quantity-button-text {
  margin: 0 -4px 0 2px;
}

.social {
  color: rgb(235, 210, 192);
  display: flex;
  justify-content: space-around;
}

.drawer-logo {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-logo img {
  margin: auto;
}
