@import url(https://fonts.googleapis.com/css2?family=Taviraj:ital,wght@0,100;0,300;0,400;0,700;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  font-weight: 300;
  font-family: "Taviraj", serif;
}

iframe {
  max-width: 100%;
  border-radius: 5px;
}

button:focus {
  outline: none;
}

select {
  margin: 10px;
  background-color: black;
  color: goldenrod;
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 9px;
}

audio {
  max-width: 100%;
}

video {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 321px) {
  h1 {
    font-size: 1.8rem;
    margin-top: 8px;
  }
}

.activity-scroller {
  height: 100vh;
  overflow: auto;
}

.btn {
  margin: 3px;
}

.contact-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  background-color: rgba(50, 49, 48, 0.8);
  padding: 10px;
}

.form-control {
  margin-bottom: 35px;
  /* background-color: gray; */
}

.contact-form {
  background-color: black;
  padding: 35px;
  color: gold;
}

.content {
  padding: 20px;
}

.inner-container {
  padding: 40px;
  max-width: 1400px;
  margin: auto;
}

.home {
  background-image: url(/static/media/homeImage.7cd18c8d.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
}

.link:hover {
  text-decoration: none;
  color: goldenrod;
}

.home-project-card {
  padding: 5px;
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.home-project-card-overlay {
  border-radius: 5px;
}

.home-project-card-image-wrapper {
  width: 100%;
  border-radius: 5px;
  height: 150px;
  overflow: hidden;
}

.home-project-card img {
  border-radius: 5px;
  max-width: 100%;
}

.product-card {
  width: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(33, 33, 33);
  margin: 20px;
}
.product-card img {
  margin: auto;
}

.product-card-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* height: 85px; */

  padding: 10px;
}

.special-btn {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  border: none;
  border-radius: 10px;
  padding: 7px;
  margin: 2px;
}

.special-btn:hover {
  background-color: rgb(57, 56, 56);
  cursor: pointer;
  color: goldenrod !important;
  text-decoration: none;
}

.special-btn-outline {
  border: 1px solid black;
  padding: 7px;
  border-radius: 10px;
  background-color: white;
}

.special-btn-outline:hover {
  background-color: #eee;
}

.titlebar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 20px;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: black;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #d5d5d5e2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 5%;
  text-align: center;
}

.overlay p {
  font-weight: 500;
}
.main-box:hover .overlay {
  opacity: 1;
}

.gallery-img {
  width: 100%;
}

.gallery-img:hover {
  cursor: pointer;
  width: 105%;
  transition: 500ms;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 150%;
  min-height: 150%;
  max-width: 120%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 40px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

.banner {
  padding-top: 60px;
  background-image: url(/static/media/golden-banner.58c9458c.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.coin-box {
  height: 250px;
  width: 250px;
  background-image: url(/static/media/golden-banner.58c9458c.jpg);
  border-radius: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.coin-box img {
  height: 97%;
  cursor: pointer;
}
/*# sourceMappingURL=HomeStyles.css.map */
.page-content {
  padding: 50px;
}

.composers-page-banner {
  width: 100%;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
}

.filtered-composers {
  height: 0px;
  overflow: hidden;
  transition: 500ms;
}

.open {
  height: 700px;
  transition: 500ms;
  overflow: scroll;
}

.letter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: gold;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  font-size: 30px;
  margin-bottom: 10px;
}

.abc-display {
  height: 850px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
/*# sourceMappingURL=ComposerListPageStyles.css.map */
.search-bar {
  width: 80%;
  margin: 30px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.input {
  background-color: white !important;
}
/*# sourceMappingURL=SearchBarStyles.css.map */
.project-card {
  padding: 30px;
  background-color: #222121;
  margin: 25px;
  border-radius: 5px;
  width: 100%;
}

.inner-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.image-container {
  width: 300px;
  margin: 20px;
}

.image-container img {
  width: 100%;
}

.textContainer {
  margin-left: 20px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: goldenrod;
  opacity: 0.2;
  margin-bottom: 20px;
}

.title {
  color: white !important;
  text-align: left;
}
/*# sourceMappingURL=ProjectsStyles.css.map */
.content {
  padding: 4px;
  color: goldenrod;
  margin: auto;
}

.coins-container {
  max-width: 1000px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.cat-card {
  width: 200px;
  height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  margin: 10px;
  background-color: black;
  border-radius: 10px;
  color: goldenrod;
  transition: 200ms;
}

.cat-card:hover {
  background-color: #141414;
  transition: 200ms;
}

.cat-card img {
  width: 70%;
  transition: 300ms;
  margin-bottom: 4px;
}

.small {
  width: 130px;
  height: 130px;
  transition: 200ms;
}

.small h5 {
  font-size: 0.8rem;
  margin-top: 4px;
}

a {
  color: gold;
}

a:hover {
  text-decoration: none;
  color: white;
}

.filtered-titles h5 {
  background-color: black;
  text-align: center;
  padding: 10px;
  margin: 3px 0px;
  transition: 200ms;
}

.filtered-titles h5:hover {
  background-color: #0a0a0a;
  transition: 200ms;
}
/*# sourceMappingURL=CategoriesStyles.css.map */
/* No CSS *//*# sourceMappingURL=VoicesStyles.css.map */
.window {
  height: 600px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 35px;
}
/*# sourceMappingURL=InstrumentsStyles.css.map */
.shop-display {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}
/*# sourceMappingURL=ShopStyles.css.map */
.collection-name {
  margin-bottom: 20px;
  cursor: pointer;
}
/*# sourceMappingURL=CollectionsStyles.css.map */
.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.3);
    z-index: 100;
    top: 0;
    left: 0;
}
.toggle-button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 30px;
  height: 2px;
  background-color: white;
}

.toggle-button__line {
  background: goldenrod;
}

.side-drawer {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  width: 300px;
  z-index: 200;
  padding: 20px;
}

.side-drawer li {
  margin-bottom: 35px;
  font-size: 18px;
}

.side-drawer ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
}

.drop-down {
  color: black;
}

.side-drawer a {
  color: gold;
  text-decoration: none;
}

.dropdown-item a {
  color: black;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: goldenrod;
}

.header {
  margin-top: 4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: gold;
}

.toolbar {
  position: fixed;
  background-color: black;
  width: 100%;
  min-height: 56px;
  padding: 30px;
  top: 0;
  left: 0;
  z-index: 200;
  text-align: center;
}

.toolbar a {
  color: goldenrod;
}

.toolbar img {
  height: 75px;
}

.toolbar__navigation {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color: goldenrod;
  text-decoration: none;
  font-size: 1.5rem;
}

/* .spacer{
    flex: 1;
} */
.header img {
  margin: 0 15px;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: gold;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #b9cdcf;
}

.lower-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  height: 50px;
}

.lower-nav a {
  color: gold;
  margin: 5px;
}

.active {
  color: goldenrod;
}
/*# sourceMappingURL=toolbar.css.map */
.page-contents {
  padding: 20px;
  background-color: goldenrod;
  color: black;
}

.info-1 {
  margin-bottom: 30px;
}

.video {
  margin-bottom: 30px;
}
/*# sourceMappingURL=SingleProductPageStyles.css.map */
.cart {
  height: 100%;
  background-color: black;
  padding: 10px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: -300px;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: 300ms ease-out;
}

.cart.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.cart-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.cart-lineitem {
  width: 100%;
  background-color: rgb(31, 31, 31);
  padding: 5px;
  border-radius: 5px;
  margin: 3px 0;
}

.cart-lineitem img {
  width: 100%;
}

.cart-lineitem-details {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 5px;
}

.cart-remove-link {
  text-align: right;
  color: rgb(100, 100, 100);
}

.cart-remove-link:hover {
  cursor: pointer;
  color: whitesmoke;
}

.cart li {
  margin-bottom: 4%;
}

.cart ul {
  /* height: 100%; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  list-style: none;
}

.drop-down {
  color: black;
}

.cart a {
  color: darkgrey;
  text-decoration: none;
}

.dropdown-item a {
  color: black;
}
.cart a:hover,
.cart a:active {
  color: goldenrod;
}

.empty-cart {
  margin-top: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.header {
  margin-top: 4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: gold;
}

.quantity-button {
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  /* padding-bottom: 10px; */
  background-color: transparent;
  color: grey;
  margin: 5px;
}

.quantity-button:hover {
  cursor: pointer;
  /* background-color: rgba(100, 100, 100, .5); */
  color: black;
}

.quantity-button-text {
  margin: 0 -4px 0 2px;
}

.social {
  color: rgb(235, 210, 192);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.drawer-logo {
  width: 100%;
  height: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.drawer-logo img {
  margin: auto;
}

.review-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 30px 0;
  background-color: #222121;
  color: gold;
  margin: 35px 0;
  border-radius: 5px;
}

.portrait-wrapper {
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: center;
  margin: 20px 20px 0px 0px;
}

.portrait-wrapper img {
  width: 100%;
}

.text-wrapper {
  max-width: 790px;
  padding: 20px;
}
/*# sourceMappingURL=GoldenBookStyles.css.map */
.content {
  padding: 35px;
  margin-top: 200px;
}

.contentContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 30px;
}

.imageContainer {
  width: 300px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.imageContainer img {
  width: 100%;
}

.textContainer {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 400px;
}
/*# sourceMappingURL=AboutUsStyles.css.map */
.content {
  padding: 35px;
  max-width: 1400;
  position: relative;
}

.review-container {
  position: relative;
}

.read-more {
  height: 150px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0), #191919);
}

.quote-container {
  position: relative;
}

.read-more-toggle {
  position: absolute;
  right: 0;
}
/*# sourceMappingURL=PressPageStyles.css.map */
