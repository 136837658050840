@import url("https://fonts.googleapis.com/css2?family=Taviraj:ital,wght@0,100;0,300;0,400;0,700;1,400&display=swap");

body {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  font-weight: 300;
  font-family: "Taviraj", serif;
}

iframe {
  max-width: 100%;
  border-radius: 5px;
}

button:focus {
  outline: none;
}

select {
  margin: 10px;
  background-color: black;
  color: goldenrod;
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 9px;
}

audio {
  max-width: 100%;
}

video {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 321px) {
  h1 {
    font-size: 1.8rem;
    margin-top: 8px;
  }
}

.activity-scroller {
  height: 100vh;
  overflow: auto;
}

.btn {
  margin: 3px;
}

.contact-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(50, 49, 48, 0.8);
  padding: 10px;
}

.form-control {
  margin-bottom: 35px;
  /* background-color: gray; */
}

.contact-form {
  background-color: black;
  padding: 35px;
  color: gold;
}

.content {
  padding: 20px;
}

.inner-container {
  padding: 40px;
  max-width: 1400px;
  margin: auto;
}

.home {
  background-image: url("./assets/homeImage.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
}

.link:hover {
  text-decoration: none;
  color: goldenrod;
}

.home-project-card {
  padding: 5px;
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.home-project-card-overlay {
  border-radius: 5px;
}

.home-project-card-image-wrapper {
  width: 100%;
  border-radius: 5px;
  height: 150px;
  overflow: hidden;
}

.home-project-card img {
  border-radius: 5px;
  max-width: 100%;
}

.product-card {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(33, 33, 33);
  margin: 20px;
}
.product-card img {
  margin: auto;
}

.product-card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* height: 85px; */

  padding: 10px;
}

.special-btn {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  border: none;
  border-radius: 10px;
  padding: 7px;
  margin: 2px;
}

.special-btn:hover {
  background-color: rgb(57, 56, 56);
  cursor: pointer;
  color: goldenrod !important;
  text-decoration: none;
}

.special-btn-outline {
  border: 1px solid black;
  padding: 7px;
  border-radius: 10px;
  background-color: white;
}

.special-btn-outline:hover {
  background-color: #eee;
}

.titlebar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: black;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #d5d5d5e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  text-align: center;
}

.overlay p {
  font-weight: 500;
}
.main-box:hover .overlay {
  opacity: 1;
}

.gallery-img {
  width: 100%;
}

.gallery-img:hover {
  cursor: pointer;
  width: 105%;
  transition: 500ms;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 150%;
  min-height: 150%;
  max-width: 120%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 40px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}
